/* We want to keep the gap between the table body and the numbers so adding back in the bottom margin */
div.dataTables_scrollBody > table {
    border-top: none;
    margin-bottom: 6px !important;
    margin-top: 0 !important;
}

/* curves/bevels are EVERYTHING :) */
div.dataTables_scrollHead th:first-child {
    border-top-left-radius: 0.25rem;
}

div.dataTables_scrollHead th:last-child {
    border-top-right-radius: 0.25rem;
}

div.dataTables_scrollBody tr:last-child td:first-child {
    border-bottom-left-radius: 0.25rem;
}

div.dataTables_scrollBody tr:last-child td:last-child {
    border-bottom-right-radius: 0.25rem;
}
