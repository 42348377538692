@import "bootstrap/scss/bootstrap.scss";
@import "datatables.net-bs5/css/dataTables.bootstrap5.css";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "bootstrap-fileinput/css/fileinput.min.css";
@import "bootstrap-fileinput/scss/themes/explorer-fa/theme.scss";
@import "flatpickr/dist/flatpickr.min.css";
@import "survey-jquery/defaultV2.min.css";
@import "select2/dist/css/select2.min.css";
@import "jquery-ui-dist/jquery-ui.min.css";
